<template>
  <div class="q-pa-md q-gutter-sm">
    <q-btn
      v-if="in_request"
      color="black"
      class="full-width"
      :loading="in_request"
      style="border-radius: 10px"
    />
    <q-btn
      v-else-if="getPrePaidCardOptions.length > 1"
      color="black"
      @click="openModal()"
      :disable="!canRescueValue()"
      :loading="in_request"
      :label="$t('global.see_options')"
      class="full-width"
      style="border-radius: 10px"
    />
    <template v-else>
      <RewardPrepaidCardComponent
        v-for="(card, i) in getPrePaidCardOptions"
        :key="i"
        :card_prefix="card.card_prefix"
        :fornecedor_nome="card.fornecedor_nome"
        :allow="card.allow"
        :option="option"
        :doc_pend="doc_pend"
        :recuse_in_request="in_request"
        :data="data"
        :disableRescue="canRescueValue() === false || doc_pend.unfilled == 1"
        :btnLabel="'global.select'"
      />
    </template>
  </div>

  <q-dialog v-model="dialog">
    <q-card class="width-container">
      <q-card-section class="row items-center q-pb-none">
        <q-space />
        <q-btn
          flat
          round
          dense
          icon="close"
          v-close-popup
          class="text-red text-weight-bolder"
        />
      </q-card-section>

      <div class="text-uppercase text-grey-8 q-ml-md" style="margin-top: -30px">
        Selecione uma opção
      </div>

      <q-card-section>
        <div class="row q-col-gutter-md">
          <div
            class="col-12 col-md-6"
            :class="{
              'q-ma-auto': getPrePaidCardOptions.length > 1,
            }"
            v-for="(card, i) in getPrePaidCardOptions"
            :key="i"
          >
            <q-card class="my-card full-height">
              <img :src="card.img" class="img" />
              <q-card-section>
                <div
                  class="text-uppercase text-weight-bolder text-black"
                  v-html="card.fornecedor_nome"
                />
                <div
                  class="text-subtitle text-grey-8"
                  v-html="card.description"
                />
              </q-card-section>

              <q-card-actions align="center" class="q-pt-none">
                <RewardPrepaidCardComponent
                  :card_prefix="card.card_prefix"
                  :fornecedor_nome="card.fornecedor_nome"
                  :allow="card.allow"
                  :option="option"
                  :doc_pend="doc_pend"
                  :recuse_in_request="in_request"
                  :data="data"
                  :disableRescue="
                    canRescueValue() === false || doc_pend.unfilled == 1
                  "
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import RewardPrepaidCardComponent from "./rewardPrepaidCardComponent.vue";
import { useGetters, useActions } from "vuex-composition-helpers";
import { onMounted, ref, defineProps } from "vue";

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  recuse_in_request: {
    type: Boolean,
    required: true,
  },
  doc_pend: {
    type: Object,
    required: true,
  },
});

const dialog = ref(false),
  in_request = ref(false);

const openModal = () => (dialog.value = true);
const canRescueValue = () => props.data.amount >= props.data.min_value;

const { getPrePaidCardOptions } = useGetters({
    getPrePaidCardOptions: "rescue_award/getPrePaidCardOptions",
  }),
  { fetchPrePaidCardOptions } = useActions({
    fetchPrePaidCardOptions: "rescue_award/fetchPrePaidCardOptions",
  });

onMounted(() => {
  in_request.value = true;
  fetchPrePaidCardOptions().finally(() => (in_request.value = false));
});
</script>

<style lang="scss" scoped>
.width-container {
  width: 800px;
  border-top: 7px solid #5d5d5d;
}

.my-card {
  .img {
    width: 200px;
    height: 180px;
    margin: auto;
  }
}
</style>
