<template>
  <div>
    <q-btn
      color="black"
      @click="openModal()"
      :disable="disableRescue"
      :loading="recuse_in_request"
      :label="$t(btnLabel)"
      class="full-width"
      style="border-radius: 10px"
    />
  </div>

  <q-dialog v-model="dialog">
    <q-card class="width-container" v-if="currentSelectedOption">
      <q-card-section class="row items-center q-pb-none">
        <q-space />
        <q-btn
          flat
          round
          dense
          icon="close"
          v-close-popup
          class="text-red text-weight-bolder"
        />
      </q-card-section>

      <prepaidCardInfos :currentSelectedOption="currentSelectedOption">
        <q-btn-group>
          <q-btn
            flat
            icon="remove"
            color="white"
            class="bg-black"
            @click="
              changeCurrentSelectedOption(currentSelectedOption.preco, 'add')
            "
            :disabled="verifyBtnRemove(currentSelectedOption.preco)"
          />
          <q-btn
            flat
            disabled
            color="white"
            :label="currentSelectedOption.preco"
            class="bg-black"
          />
          <q-btn
            flat
            icon="add"
            color="white"
            class="bg-black"
            @click="
              changeCurrentSelectedOption(currentSelectedOption.preco, 'remove')
            "
            :disabled="verifyBtnPlus(currentSelectedOption.preco)"
          />
        </q-btn-group>
      </prepaidCardInfos>
      <div class="row justify-center text-weight-medium">
        <q-option-group
          inline
          color="black"
          v-model="group"
          :options="options"
          v-if="rechargeIndexes.length && PrepaidIndexes.length"
        />
      </div>
      <prepaidRescue
        v-if="group == 0"
        :data="data"
        :option="option"
        :doc_pend="doc_pend"
        :indexes="PrepaidIndexes"
        @submitForm="confirmModal"
        :selectedOption="currentSelectedOption"
      />
      <prepaidRecharge
        v-if="group == 1 && rechargeIndexes"
        :data="data"
        :option="option"
        :doc_pend="doc_pend"
        :card_prefix="card_prefix"
        :indexes="rechargeIndexes"
        @submitForm="confirmModal"
        :selectedOption="currentSelectedOption"
      />
    </q-card>
  </q-dialog>
  <reward-confirmation-modal-component
    v-model="confirmationDialog"
    :image="'/present-icon.svg'"
    :message="$t('my_points.reward_gift.final_confirmation')"
    @submit="onSubmit(group)"
    :loading="loading"
  />
</template>

<script setup>
import rewardConfirmationModalComponent from "@/modules/main/components/rewardConfirmationModalComponent.vue";
import prepaidCardInfos from "./prepaidCardInfos.vue";
import prepaidRecharge from "./prepaidRecharge.vue";
import prepaidRescue from "./prepaidRescue.vue";

import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { useGetters, useActions } from "vuex-composition-helpers";
import { onMounted, ref, watch, defineProps } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  fornecedor_nome: {
    type: String,
    required: true,
  },
  allow: {
    type: Array,
    required: true,
  },
  card_prefix: {
    type: Array,
    required: true,
  },
  option: {
    type: Object,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  recuse_in_request: {
    type: Boolean,
    required: true,
  },
  doc_pend: {
    type: Object,
    required: true,
  },
  disableRescue: {
    type: Boolean,
    required: true,
  },
  btnLabel: {
    type: String,
    default: "global.select",
  },
});

const { t } = useI18n(),
  PrePaidCardInfo = ref(null),
  rechargeOptions = ref(null),
  PrepaidIndexes = ref(null),
  rechargeIndexes = ref(null),
  currentSelectedOption = ref(null),
  loading = ref(false),
  dataToSend = ref(null);

const { getPrePaidCardInfo } = useGetters({
    getPrePaidCardInfo: "rescue_award/getPrePaidCardInfo",
  }),
  { rescueReward, fetchReward, prePaidCardRescue, fetchCanRescuePaidCard } =
    useActions({
      rescueReward: "rescue_award/rescueReward",
      fetchReward: "rescue_award/fetchReward",
      prePaidCardRescue: "rescue_award/prePaidCardRescue",
      fetchCanRescuePaidCard: "rescue_award/fetchCanRescuePaidCard",
    });

const options = [0, 1].map((item) => ({
  label: t(`my_points.reward_pre_paid_card.op${item}label`),
  value: item,
}));

const group = ref(null),
  dialog = ref(false),
  confirmationDialog = ref(false);

const confirmModal = (values) => {
  dataToSend.value = values;
  confirmationDialog.value = true;
};

const openModal = async () => {
  await prePaidCardRescue({
    fornecedor_nome: props.fornecedor_nome,
    value: props.option.total_points,
    tipo_produto: props.allow,
  }).then(() => {
    setPrepaidAndRechargeOptions();
    dialog.value = true;
  });
};

const setPrepaidAndRechargeOptions = () => {
  PrePaidCardInfo.value = getPrePaidCardInfo.value.filter(
    (e) => e.tipo_produto == "FISICO"
  );
  rechargeOptions.value = getPrePaidCardInfo.value.filter(
    (e) => e.tipo_produto == "RECARGA"
  );
  PrepaidIndexes.value = PrePaidCardInfo.value.map((e) => e.preco);
  rechargeIndexes.value = rechargeOptions.value.map((e) => e.preco);

  if (PrepaidIndexes.value.length > 0) group.value = 0;
  else if (PrepaidIndexes.value.length == 0 && rechargeIndexes.value.length > 0)
    group.value = 1;
  else group.value = 0;
};

const verifyBtnPlus = (value) => {
    if (group.value == 0) return value == PrepaidIndexes.value[0];
    else return value == rechargeIndexes.value[0];
  },
  verifyBtnRemove = (value) => {
    const pPaidIndexes = PrepaidIndexes.value;
    const rIndexes = rechargeIndexes.value;
    if (group.value == 0) return value == pPaidIndexes[pPaidIndexes.length - 1];
    else return value == rIndexes[rIndexes.length - 1];
  };

const changeCurrentSelectedOption = (value, addOrRemove) => {
  const pPaidInfo = PrePaidCardInfo.value,
    rOptions = rechargeOptions.value;

  if (group.value == 0) {
    const cIndex = pPaidInfo.findIndex((e) => e.preco == value);
    if (addOrRemove == "add")
      currentSelectedOption.value = pPaidInfo[cIndex + 1];
    else currentSelectedOption.value = pPaidInfo[cIndex - 1];
  } else {
    const cIndex = rOptions.findIndex((e) => e.preco == value);
    if (addOrRemove == "add")
      currentSelectedOption.value = rOptions[cIndex + 1];
    else currentSelectedOption.value = rOptions[cIndex - 1];
  }
};

const onSubmit = () => {
  if (!props.disableRescue) {
    loading.value = true;
    rescueReward(dataToSend.value)
      .then(() =>
        fetchReward().then(() => {
          notifySuccess();
          window.location.reload();
        })
      )
      .catch(() => notifyError())
      .finally(() => (loading.value = false));
  }
};

onMounted(() => fetchCanRescuePaidCard());

watch(group, (value) => {
  if (value == 0) currentSelectedOption.value = PrePaidCardInfo.value[0];
  else currentSelectedOption.value = rechargeOptions.value[0];
});
</script>

<style lang="scss" scoped>
.width-container {
  min-width: 45vw;
  border-top: 7px solid #5d5d5d;
}

@media (max-width: 800px) {
  .width-container {
    min-width: 95vw;
  }
  .prod-description {
    width: 100%;
  }
}
</style>
