<template>
  <div class="reward default-rounded q-pa-sm text-center bg-grey-2 full-height">
    <div class="icon-content text-center">
      <img :src="option.image" width="170" />
    </div>
    <div class="title" v-html="title" />
    <q-expansion-item
      expand-separator
      label="Informações do resgate"
      header-class="q-mt-md text-left"
    >
      <q-card class="info">
        <q-card-section>
          <p v-for="(detail, i) in option.details" :key="i">
            - <span v-html="detail" />
          </p>
        </q-card-section>
        <div class="footer text-center">
          <RewardGiftModal
            v-if="option.type == 3"
            :option="option"
            :recuse_in_request="in_request"
            :disabled="canRescueValue() === false || doc_pend.unfilled == 1"
            :data="{
              point,
              option,
              amount,
              min_value,
              doc_pend: doc_pend.unfilled == 1,
              can_rescue: canRescueValue() == false || doc_pend.unfilled == 1,
            }"
          />
          <RewardMoneyOrVoucher
            v-if="type == 2 || type == 1"
            :option="option"
            :doc_pend="doc_pend"
            :recuse_in_request="in_request"
            :data="{ amount, min_value, point }"
            :disabled="canRescueValue() === false || doc_pend.unfilled == 1"
          />

          <SelectPrepaidOption
            v-if="type == 6"
            :option="option"
            :doc_pend="doc_pend"
            :recuse_in_request="in_request"
            :data="{
              point,
              amount,
              option,
              min_value,
            }"
            :disabled="canRescueValue() === false || doc_pend.unfilled == 1"
          />
        </div>
      </q-card>
    </q-expansion-item>
    <div class="q-mt-md">
      <p
        v-if="doc_pend.unfilled"
        class="text-negative text-center text-subtitle-small"
      >
        {{ $t("my_points.award.doc_pend_rescue_value") }}
        <q-icon
          name="warning"
          tooltip="Clique"
          style="font-size: 20pt; cursor: pointer"
          @click="showDocPendModal()"
        >
          <q-tooltip anchor="bottom middle" self="top middle">
            {{ $t("my_points.award.doc_pend_rescue_alert") }}
          </q-tooltip>
        </q-icon>
      </p>
      <p
        v-if="canRescueValue() === false"
        class="text-negative text-center text-subtitle-small"
      >
        {{ $t("my_points.award.min_rescue_value") }}
        <br />
        <span>{{ toMoneyLabel(min_value) }}</span>
      </p>
    </div>
  </div>
  <docs-pend-component
    v-model="docPend"
    :docs_pend="doc_pend.pend"
    :label="'my_points.award.doc_pend_rescue_message'"
  />
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import RewardMoneyOrVoucher from "./money/RewardMoney";
import docsPendComponent from "./docsPendComponent.vue";
import RewardGiftModal from "./gift/RewardGiftModal.vue";
import { ref } from "vue";
import SelectPrepaidOption from "./prepaidCard/SelectPrepaidOption.vue";

const { useGetters } = createNamespacedHelpers("rescue_award");

export default {
  name: "Reward",

  components: {
    RewardMoneyOrVoucher,
    RewardGiftModal,
    SelectPrepaidOption,
    docsPendComponent,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    point: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    receiver: {
      type: String,
      required: true,
    },
    min_value: {
      type: Number,
      required: true,
    },
    descriptions: {
      type: Array,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
    doc_pend: { type: Object, required: true },
  },

  setup(props) {
    const docPend = ref(false);
    const { in_request } = useGetters({
      in_request: "tradeInRequest",
    });

    const canRescueValue = () => props.amount >= props.min_value,
      showDocPendModal = () => (docPend.value = !docPend.value);

    return {
      toMoneyLabel,
      in_request,
      docPend,
      canRescueValue,
      showDocPendModal,
    };
  },
};
</script>

<style scoped lang="scss">
.reward {
  border: 2px solid #bebebe;
  .title {
    font-size: 13pt;
    text-transform: uppercase;
    font-weight: bold;
  }
  .info {
    text-align: justify;
    font-size: 10pt;
  }
}
</style>
